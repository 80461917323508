import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import loadable from "@loadable/component"

import { IMG } from "../const"
import {
  REASONS,
  MVP_DEVELOPMENT_STAGES,
  TITLES,
  OPTIONS_LIST,
  TARIFFS,
  DEVELOPMENT_SERVICES,
} from "../data/mvp-development"
import Layout from "../components/layout"
import "../assets/styles/commons/_loading-state.scss"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/mvp-development.module.scss"
import Headline from "../components/headline"
import OptionsList from "../components/options-list"
import TariffsList from "../components/mvp-development/TariffsList"

const TitleBlock = loadable(() => import("../components/title-block"))
const Accordion = loadable(() => import("../components/accordion"))
const ServicesList = loadable(() => import("../components/services-list"))
const CaseStudies = loadable(() => import("../components/case-studies"))
const ColoredSection = loadable(() => import("../components/colored-section"))
const ContactForm = loadable(() => import("../components/contact-form"))

const MVPDevelopments = () => {
  return (
    <Layout
      pageTitle="MVP Development Services"
      metaDescription="Launch your MVP swiftly with LaSoft's development prowess. Turn your ideas into reality."
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-mvp.jpg`}
    >
      {({ width }) => {
        return (
          <main className={cx(styles.mvpDevelopmentBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className={"strongText"}>
                      we bring your startup ideas to life through our
                    </p>

                    <h1 className={"withIndent"}>
                      <strong>MVP</strong> development services
                    </h1>

                    <div className={"rightColumn"}>
                      <p>
                        You can expect the initial results back within 3-4
                        weeks, and the complete functional system can be
                        delivered within 6-9 months.
                      </p>
                      <div className="onDesktop" id="mvp-first-dt">
                        <Link
                          to="#mvp-contact-form"
                          className="goldFatLink mvp-test smallText"
                        >
                          Turn Your Idea into Reality
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={styles.flexBlock}>
                    <div className={styles.strongTextBlock}>
                      <h3 className={styles.strongText}>
                        our minimal viable product development service is for:
                      </h3>
                    </div>
                    <OptionsList list={DEVELOPMENT_SERVICES} />
                  </div>
                  <div className="onMobile" id="mvp-first-mb">
                    <Link
                      to="#mvp-contact-form"
                      className="goldFatLink mvp-test smallText"
                    >
                      Turn Your Idea into Reality
                    </Link>
                  </div>
                </div>
              </Headline>

              <section className={cx(styles.productServices, "section")}>
                <TitleBlock options={TITLES.product_services} />
                <div className={styles.productServices__list}>
                  <OptionsList list={OPTIONS_LIST} ordered />
                </div>
              </section>

              <section className={cx(styles.answers, "section")}>
                <TitleBlock options={TITLES.answers} />
                <ServicesList services={REASONS.items} text={REASONS.text} />
              </section>

              <section className={cx(styles.packages, "section")}>
                <TitleBlock options={TITLES.packages} />
                <div className={styles.tariffsListWrapper}>
                  <TariffsList list={TARIFFS} />
                </div>
              </section>

              <section className={cx(styles.how, "section")}>
                <TitleBlock options={TITLES.structured} />
                <div className={styles.accordionWrapper}>
                  <Accordion options={MVP_DEVELOPMENT_STAGES} />
                </div>
              </section>

              <CaseStudies title="" screenWidth={width} />

              <ColoredSection options={TITLES.colored} isTest={true} />
            </article>
            <section className="footerForm common-kit">
              <div className="section" id="mvp-contact-form">
                <ContactForm formStatID="CommonFooter" />
              </div>
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default MVPDevelopments
